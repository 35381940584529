import Button from "@mui/material/Button";
// import Video from "./Video";
import { YouTubePlayer } from "./index";

function MainAudioBook() {
  return (
    <>
      <div className="block md:flex gap-4 p-10">
        <div className="block gap-4 md:shrink w-full sm:w-1/2 m-auto">
          <div className="block md:flex gap-2 p-2">
            <div className="block gap-4 md:shrink w-full md:w-1/2 p-1 m-auto border-4 border-solid rounded-lg">
              <img
                alt="audiobook"
                src="./img/Page-2/AudioBook.jpg"
                className="text-center"
              />
            </div>
            <div className="block gap-4 md:shrink w-full md:w-1/2 m-auto">
              <p className="xl:text-6xl lg:text-5xl md:text-4xl text-3xl font-bold text-black text-center line-through decoration-double tracking-widest leading-8">
                $17.99
              </p>
              <p className="xl:text-6xl lg:text-5xl md:text-4xl text-3xl text-white text-center tracking-widest leading-8">
                NOW!
              </p>
              <p className="xl:text-6xl lg:text-5xl md:text-4xl text-3xl font-bold text-white text-center tracking-widest leading-8">
                $9.99
              </p>
            </div>
          </div>
          <div className="">
            <p className="text-2xl text-white text-center">
              Purchase & Download The AudioBook :
            </p>
          </div>
          <div className="flex justify-center items-center">
            <Button
              className="btn-buy"
              variant="text"
              href="https://www.fatfreecartpro.com/i/11t0v"
              style={{
                backgroundImage: "url('./img/Page-2/Buy-Now-button.png')",
              }}
            ></Button>
          </div>
        </div>
        <div className="block gap-4 md:shrink w-1/2 m-auto">
          <p className="xl:text-5xl lg:text-4xl md:text-2xl text-xl text-white text-center">
            At age 50 Buzz chucked it all for a tropical paradise and ends up
            living in a Hawaiian jungle with pizza making outlaw hippies.
          </p>
          <p className="xl:text-5xl lg:text-4xl md:text-2xl text-xl text-white text-center">
            And finds out why
          </p>
          <p className="xl:text-5xl lg:text-4xl md:text-2xl text-xl font-bold text-white text-center">
            Da Mana is Everywhere!
          </p>
        </div>
      </div>

      <div className="block md:flex gap-4 p-10">
        <div className="block gap-4 md:shrink w-full md:w-1/2 p-10">
          <YouTubePlayer url="https://youtu.be/qAS1HVhh9_0" />
          {/* <Video propsURL="./public/video.mp4" controls={true} /> */}
        </div>
        <div className="block gap-4 md:shrink w-full md:w-1/2 m-auto">
          <p className="xl:text-4xl lg:text-3xl md:text-2xl text-xl font-bold text-white text-center">
            The Spiritual Pizza AudioBook Experience!
          </p>
          <p className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-left">
            • Words, Music and Stories Performed by Buzz and the Outlaws
          </p>
          <p className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-left">
            • PDF with Table of Contents, pictures and credits.
          </p>
          <p className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-left">
            • MP3 AudioBook, 33 Chapters, 7 Hours
          </p>
          <p className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-white text-left">
            • Major Credit Cards & PayPal
          </p>
        </div>
      </div>
    </>
  );
}
export default MainAudioBook;
